import axios from "axios";
import { Front_API_URL } from "./Front/Constants";
import { Front_URL } from "./Front/constantsurl";
import React, { useEffect, useState } from "react";
import { Link, useParams,useLocation  } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function Events() {
    const { id } = useParams(); 
    let data = useLocation();

    const [current_page, setCurrent_page] = useState(1);  
    const [links, setLinks] = useState([]);  
    const [events, setEvents] = useState([]); 
    const [eventages,setEventAges]=useState([]);
    const [error, setError] = useState("");

    // States for filters
    const [selectedCountry, setSelectedCountry] = useState(""); 
    const [selectedMode, setSelectedMode] = useState(""); 
    const [countryList, setCountryList] = useState([]);
    const [minAge, setMinAge] = useState(""); 
    const [maxAge, setMaxAge] = useState(""); 
    const location = useLocation();

    
    useEffect(() => { 
        if (data.state?.linkChange) {
          setCurrent_page(1); 
          setEvents([]); 
          const updatedCountry = "";
          setSelectedCountry(updatedCountry); 
          fetchData(1,updatedCountry); 
        }
      }, [id,data.state]);
   

    const handleMinAgeChange = (e) => {
        const value = e.target.value;
        setMinAge(value);

        if (maxAge !== "All" && parseInt(value) > parseInt(maxAge)) {
            setError("Minimum age cannot be greater than maximum age.");
        } else {
            setError("");
        }
    };

    const defaultName = id === "1" ? "Music" : id === "2" ? "Creative Writing":id === "3" ? "Drama" :id === "4" ? "Visual Arts" :id === "5" ? "Stem":id === "6" ? "Languages" : id === "6" ? "Sports":"Default Name";

    const handleMaxAgeChange = (e) => {
        const value = e.target.value;
        setMaxAge(value);

        if (minAge !== "All" && parseInt(minAge) > parseInt(value)) {
            setError("Maximum age cannot be less than minimum age.");
        } else {
            setError("");
        }
    };

    const handleModeChange = (event) => { 
        const { value } = event.target;
        setSelectedMode(value);
        setCurrent_page(1)
 
        
    };


    useEffect(() => {
        setSelectedCountry('');
        setSelectedMode('');
        setMinAge('All');
        setMaxAge('All');
    }, [location]);

    // Fetch events
    const fetchData = async (page,country = selectedCountry, mode = selectedMode, min = minAge, max = maxAge) => {  
        try {
            // const countryQuery = 
            // selectedCountry && selectedCountry !== "248"
            //     ? `&country=${Array.isArray(selectedCountry) ? selectedCountry.join(",") : selectedCountry}`
            //     : "";

            const countryQuery =
            country && country !== "248"
              ? `&country=${Array.isArray(country) ? country.join(",") : country}`
              : "";

            const modeQuery = selectedMode && selectedMode !== "All" ? `&mode=${selectedMode}` : "";
            const minAgeQuery = minAge && minAge !== "All" ? `&min_age=${minAge}` : `&min_age=`;
            const maxAgeQuery = maxAge && maxAge !== "All" ? `&max_age=${maxAge}` : `&max_age=`;

            const response = await axios.get(
                `${Front_API_URL}/allEvents/${id}?page=${page || current_page}${countryQuery}${modeQuery}${minAgeQuery}${maxAgeQuery}`
            );

            setEvents(response.data.data); 
            // setCurrent_page(response.data.current_page); 
            setLinks(response.data.links); 
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    const fetchCountries = async () => {
        try {
            const response = await axios.get(`${Front_API_URL}/countries`);
            let countries = response.data;
            const country248 = countries.find((country) => country.id === 248);
            const country249 = countries.find((country) => country.id === 249);
            countries = countries.filter((country) => country.id !== 248 && country.id !== 249);
            const orderedCountries = [country248, country249, ...countries];
            setCountryList(orderedCountries);
        } catch (error) {
            console.error("Error fetching countries", error);
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);  



    useEffect(() => {
        fetchData(current_page, selectedCountry, selectedMode, minAge, maxAge);
    }, [current_page, id, selectedCountry, selectedMode,minAge,maxAge]);

    const pagination = (url) => { 
        if (url) {
            const page = new URL(url).searchParams.get('page');
            setCurrent_page(Number(page)); 
            setEvents([]); 
            setSelectedCountry("")
            fetchData(Number(page));
        }
    };



    return (
        <>
            {/* Page Title Area */}
            <div className="banner-area events-bg">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Our Competitions</h2>
                                <ul>
                                    <li>
                                        <Link to="/"> Home </Link>
                                        <span class="straight-line">|</span>
                                        <p className="active">Competitions</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Title Area */}

            {/* Filters Section */}
            <div className="container">
                <div className="row my-4">
                    <div className="col-md-3 main-filter-cls">
                        <div className="row filter-bx">
                        <h3>Filter for {events?.[0]?.name ||defaultName}</h3>
                            <div className="col-md-12 filter-bx-12">
                                <label>Select Country</label>
                                <select
                                    className="form-control"
                                    value={selectedCountry}
                                    onChange={(e) => setSelectedCountry(e.target.value)}
                                >
                                    <option value="" disabled>Choose</option>
                                    {countryList.map((country, index) => (
                                        <option key={index} value={country.id}>
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-md-12 filter-bx-12">
                                <label>Select Mode</label>
                                <select
                                    className="form-control"
                                    value={selectedMode}
                                    onChange={handleModeChange}
                                >   
                                     <option value="" disabled>Choose</option>
                                    <option value="All">All</option>
                                    <option value="1">Online</option>
                                    <option value="2">Onsite</option>
                                </select>
                            </div>


                             <div className="row">
                                <div className="col-md-6 filter-bx-12">
                                    <label>From</label>
                                    <select
                                        className="form-control"
                                        value={minAge}
                                        onChange={handleMinAgeChange}
                                    >
                                        <option value="All" disabled>Choose</option>
                                        {Array.from({ length: 18 }, (_, i) => i + 4).map((age) => (
                                            <option key={age} value={age}>
                                                {age}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-6 filter-bx-12">
                                    <label>To</label>
                                    <select
                                        className="form-control"
                                        value={maxAge}
                                        onChange={handleMaxAgeChange}
                                    >
                                        <option value="All" disabled>Choose</option>
                                        {Array.from({ length: 18 }, (_, i) => i + 4).map((age) => (
                                            <option key={age} value={age}>
                                                {age}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {error && (
                                    <div className="col-md-12">
                                        <p style={{ color: "red" }}>{error}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <section className="events">
                            <div className="container">
                                {events.length === 0 ? (
                                    <div className="no-data-found text-center">
                                        <h3>No Data Found!</h3>
                                    </div>
                                ) : (
                                    <>
                                        <div className="row">
                                            {events.map((item, index) => (
                                                <div className="col-lg-12 col-md-12 p_0" key={index}>
                                                    <div className="single-events event-cls">
                                                        <div className="events-img">
                                                            <Link to={`/events-details/${item.c_id}`}>
                                                                <img src={`${Front_URL}/storage/images/${item.image}`} alt="competitions" />
                                                            </Link>
                                                        </div>
                                                        <div className="content">
                                                            <Link to={`/events-details/${item.c_id}`}>
                                                                <h2>{item.title}</h2>
                                                            </Link>
                                                            <div className="competition-details-r">
                                                                <h5 ><span class="w-48">Mode</span> <span class="w-4">:</span>  
                                                                <span class="w-48">{item.mode === 1 ? 'Online' : item.mode === 2 ? 'Onsite' : item.mode === 3 ? 'Online/Onsite' : ''} </span> </h5>
                                                                <h5><span class="w-48">Country</span> <span class="w-4">:</span>  <span class="w-48">{item.countryname}</span></h5>
                                                                <h5><span class="w-48">Age Group</span> <span class="w-4">:</span>  <span class="w-48">{item.age_group}</span></h5>
                                                                <h5><span class="w-48">Competition Name</span ><span class="w-4"> :</span>  <span class="w-48">{item.name}</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    
                                      <div className="row">
                                        <div className="col-lg-12 text-center">
                                            <ul className="pagination">
                                                {/* Check if there is a previous page and show "<" */}
                                                {links[0]?.url && (
                                                    <li className="page-item">
                                                        <a
                                                            href="#"
                                                            className="page-link waves-effect"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                pagination(links[0].url);
                                                                window.scrollTo({
                                                                    top: 0,
                                                                    behavior: 'smooth' // Smooth scrolling to the top
                                                                });
                                                            }}
                                                        >
                                                            {"<"}
                                                        </a>
                                                    </li>
                                                )}

                                                {/* Render page numbers */}
                                                {links && links.slice(1, -1).map((item, index) => (
                                                    <li key={index} className={`page-item ${item.active ? 'active' : ''}`}>
                                                        <a
                                                            href="#"
                                                            className="page-link waves-effect"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                pagination(item.url);
                                                                window.scrollTo({
                                                                    top: 0,
                                                                    behavior: 'smooth'
                                                                });
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </a>
                                                    </li>
                                                ))}

                                                {/* Check if there is a next page and show ">" */}
                                                {links[links.length - 1]?.url && (
                                                    <li className="page-item">
                                                        <a
                                                            href="#"
                                                            className="page-link waves-effect"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                pagination(links[links.length - 1].url);
                                                                window.scrollTo({
                                                                    top: 0,
                                                                    behavior: 'smooth'
                                                                });
                                                            }}
                                                        >
                                                            {">"}
                                                        </a>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                      </div>

                                    </>
                                )}
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
}
