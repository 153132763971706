import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Query from "./Query";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Front_API_URL } from "./Front/Constants";
import { Front_URL } from "./Front/constantsurl";
import PostStory from "./PostStory";
import PostedStory from "./PostedStory";

export default function EventsDetails() {
  const { id } = useParams();
  const [EventDetails, setEventDetails] = useState([]);
  const [moreEvents, setMoreEvents] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn") === "true";
    setIsLoggedIn(loggedIn);
  }, []);


  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${Front_API_URL}/frontcompetitionDetails/${id}`
      );
    
      if (!response.data || Object.keys(response.data).length === 0) {
        setEventDetails(null); // Set to null to indicate no data
      } else {
       
        setEventDetails(response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setEventDetails(null);
      } else {
        console.error("Error fetching data", error);
      }
    }
  };
  

  const fetchMoreEvents = async () => {
    try {
      const response = await axios.get(`${Front_API_URL}/moreEvents/${id}`);
      setMoreEvents(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchMoreEvents();
  }, [id]);

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + "...";
  };

  const handleLoginRedirect = () => {
    navigate("/signin");
  };

  return (
    <>

        {EventDetails === null ? (
              <div className="banner-area data-not-found-section">
                <div className="d-table">
                  <div className="d-table-cell">
                    <div className="container">
                      <div className="page-title-content">
                        <h3>No Data Found!</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          ) : (
            <>
            
              <div className="banner-area events-details">
                <div className="d-table">
                  <div className="d-table-cell">
                    <div className="container">
                      <div className="page-title-content">
                        <h2>{EventDetails.title}</h2>
                        <ul>
                          <li>
                            <Link to="/"> Home </Link>
                          <span class="straight-line">|</span>
                            <Link
                              onClick={() => navigate(-1)}
                              style={{
                                border: "none",
                                background: "none",
                                color: "#fff",
                                cursor: "pointer",
                              }}
                            >
                              Competitions
                            </Link>
                            <span class="straight-line">|</span>
                            <p className="active">Competition details</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section className="single-event">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-7">
                    <div className="event-slider">
                      <div className="single-slider">
                        <div className="event-img">
                          <img
                            src={`${Front_URL}/storage/images/${EventDetails.image}`}
                            alt="event"
                          />
                        </div>
                        <div className="content">
                          <h2>
                            <a href={EventDetails.link} target="_blank" rel="noopener noreferrer">
                            {EventDetails.title}
                            </a>
                          </h2>
                          <div className="competition-details competition_bg">
                                                          <h5><span>Mode:</span> {EventDetails.mode === 1 ? 'Online' : EventDetails.mode === 2 ? 'Offline' : ''} </h5>
                                                          <h5><span>Country:</span> {EventDetails.countryname} </h5>
                                                          <h5><span>Age Group:</span> {EventDetails.age_group}</h5>
                                                          <h5><span>Competition name:</span>{EventDetails.name}</h5>
                                                      </div> 
                          <p>{stripHtmlTags(EventDetails.description)}</p>
                          <h2 className="link_heading">
                            <a href={EventDetails.link} target="_blank" rel="noopener noreferrer">
                            <span className="link_bg">Apply here <i class="fa-solid fa-arrow-right"></i> </span>
                             {EventDetails.link}
                            </a>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="share">
                      <ul className="share-list">
                        <li>
                          <p className="share-p">Share</p>
                        </li>
                        <li>
                          <a href="">
                            <i className="flaticon-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="">
                            <i className="flaticon-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="">
                            <i className="flaticon-envelope"></i>
                          </a>
                        </li>
                        <li>
                          <a href="">
                            <i className="flaticon-google-plus"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-5">
                    <div className="right-content">
                      <form>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control about-search"
                            placeholder="Search..."
                          />
                        </div>
                        <button type="submit" disabled>
                          {" "}
                          <i className="flaticon-search"></i>
                        </button>
                      </form>

                      <p className="visit">More Competitions</p>

                      {moreEvents.slice(0, 2).map((event) => (
                        <div className="single-content" key={event.c_id}>
                          <div className="content-img">
                            <Link to={`/events-details/${event.c_id}`}>
                              <img
                                src={`${Front_URL}/storage/images/${event.image}`}
                                alt="blog"
                              />
                            </Link>
                          </div>
                          <div className="content">
                            <Link to={`/events-details/${event.c_id}`}>
                              <h2>{event.title}</h2>
                            </Link>
                            {/* <p className="calender">
                              <i className="flaticon-calendar"></i>{" "}
                              {event.comp_date}
                            </p> */}
                          
                            <div className="competition-details-r">
                              <h5><span class="w-48">Mode </span><span class="w-4">:</span> <span class="w-48">{event.mode === 1 ? 'Online' : event.mode === 2 ? 'Offline' : ''} </span></h5>
                              <h5><span class="w-48">Country</span><span class="w-4">:</span> <span class="w-48">{event.countryname} </span></h5>
                         
                            </div>

                            <div className="competition-details-r">
                              
                              <h5><span class="w-48">Age Group</span><span class="w-4">:</span> <span class="w-48">{event.age_group}</span></h5>
                              <h5><span class="w-48">Competition name</span><span class="w-4">:</span><span class="w-48"> {event.name}</span></h5>
                            </div>

                                                      
                            <p>
                              {truncateText(
                                stripHtmlTags(event.description),
                                106
                              )}
                            </p>
                            {/* <div dangerouslySetInnerHTML={{ __html: event.description }} /> */}

                            <Link
                              to={`/events-details/${event.c_id}`}
                              className="line-bnt"
                            >
                              Read More <i className="flaticon-next"></i>
                            </Link>
                          </div>
                        </div>
                      ))}
                      <Link to={`/events/${EventDetails.id}`} className="box-btn">
                        More Competitions
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <PostedStory eventId={id} pageName="CompetitionDetails"/>
            {isLoggedIn ? (
            <PostStory pageName="CompetitionDetails" eventId={id} />
          ) : (
            <div style={{
              display: 'flex',
              justifyContent: 'center',  
              alignItems: 'center',      
            }}>
              <button
              onClick={handleLoginRedirect}
              style={{
                backgroundColor: '#007bff', 
                color: '#fff',              
                border: 'none',             
                padding: '10px 20px',       
                fontSize: '16px',           
                borderRadius: '5px',        
                cursor: 'pointer',         
                textAlign: 'center',        
                textDecoration: 'none',     
                display: 'inline-block',    
                transition: 'background-color 0.3s ease',
              }}
              className="login-button"
            >
              Post a Story
            </button>

            </div>
          )}
            
            </>
          )}
    </>
  );
}
