import axios from "axios";
import { Front_API_URL } from "../Front/Constants";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Front_URL } from "../Front/constantsurl";
import ChangePasswordModal from "../ChangePasswordModal"; 


// -------anjani-code--new---
export default function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [isSubmenuOpens, setIsSubmenuOpens] = useState(false);

  const handleNavToggle = () => {
    setIsNavOpen((prev) => !prev); // Toggle the nav open/close state
  };

  const SubhandleNavToggle = () => {
    setIsSubmenuOpen((prev) => !prev); // Toggle the nav open/close state
  };

  const SubhandleNavToggles = () => {
    setIsSubmenuOpens((prev) => !prev); // Toggle the nav open/close state
  };
  // ---anjani-code-new----

  const [category, setCategory] = useState([]);
  const [event, setEvent] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userProfileImage, setUserProfileImage] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const handleShowPasswordModal = () => setShowPasswordModal(true);
  const handleClosePasswordModal = () => setShowPasswordModal(false);

  const fetchCategory = async () => {
    try {
      const response = await axios.get(`${Front_API_URL}/category`);
      setCategory(response.data);
    } catch (error) {
      console.error("Error fetching category data", error);
    }
  };

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${Front_API_URL}/frontEvents`);
      setEvent(response.data);
    } catch (error) {
      console.error("Error fetching event data", error);
    }
  };

  useEffect(() => {
    fetchCategory();
    fetchEvents();

    const loggedIn = localStorage.getItem("loggedIn") === "true";
    setIsLoggedIn(loggedIn);

    if (loggedIn) {
      const userProfile = localStorage.getItem("userProfileImage");
      const userProfileImage =
        userProfile && userProfile !== "null"
          ? `${Front_URL}/storage/images/${userProfile}`
          : `${Front_URL}/storage/images/default-profile.png`;

      setUserProfileImage(userProfileImage);
    }
  }, []);

  return (
    <>
      <style>
        {`
        .show {
          display: block;
        }
        .hide {
          display: none;
        }
        
      `}
      </style>
      <div className="navbar-area">
        <div className="mobile-nav">
          <div className="mobile-nav mean-container">
            <div className="mean-bar">
              <a
                // href="#nav"
                className="meanmenu-reveal"
                onClick={handleNavToggle}
                style={{ background: "", color: "", right: 0, left: "auto" }}
              >
                <span>
                  <span>
                    <span />
                  </span>
                </span>
              </a>
              <nav className="mean-nav">
                <ul className={`navbar-nav ${isNavOpen ? "show" : "hide"}`}>
                  <li className="nav-item">
                    <a href="/" className="nav-link active">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/about-us" className="nav-link">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link dropdown-toggle"
                      onClick={SubhandleNavToggle}
                    >
                      Competitions
                      <span
                        style={{
                          position: "absolute",
                          right: "15px",
                          bottom: `20px`,
                        }}
                      >
                        <i
                          class="fas fa-angle-down"
                          style={{ display: "block" }}
                        ></i>
                      </span>
                    </a>
                    <ul
                      className={`dropdown-menu ${
                        isSubmenuOpen ? "show" : "hide"
                      }`}
                    >
                      {category.map((item, index) => (
                        <li className="nav-item" key={index}>
                          <Link to={`/events/${item.id}`} className="nav-link">
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link dropdown-toggle"
                      onClick={SubhandleNavToggles}
                    >
                      For Companies
                      <span
                        style={{
                          position: "absolute",
                          right: "15px",
                          bottom: `20px`,
                        }}
                      >
                        <i
                          class="fas fa-angle-down"
                          style={{ display: "block" }}
                        ></i>
                      </span>
                    </a>
                    <ul
                      className={`dropdown-menu ${
                        isSubmenuOpens ? "show" : "hide"
                      } `}
                    >
                      {event.map((item, index) => (
                        <li className="nav-item" key={index}>
                          <Link
                            to={`/submit-your-competition`}
                            className="nav-link"
                          >
                            Submit a new competition
                          </Link>
                        </li>
                      ))}
                     
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link to="/blogs" className="nav-link">
                      Inspirational Stories
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/faq" className="nav-link">
                      FAQ
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/contact" className="nav-link">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <a href="index.html" className="logo">
              <img
                src="assets/images/logo1.png"
                className="main-logo"
                alt="logo"
              />
              <img
                src="assets/images/logo2.png"
                className="white-logo"
                alt="logo"
              />
            </a>
          </div>
        </div>

        <div className="main-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link className="navbar-brand" to="/">
                <img
                  src="/assets/images/logo1.png"
                  className="main-logo"
                  alt="logo"
                />
                <img
                  src="/assets/images/logo2.png"
                  className="white-logo"
                  alt="logo"
                />
                <span
                  style={{
                    marginTop: "8px", // Adjust space between logo and text
                    fontSize: "16px", // Adjust text size
                    fontWeight: "bold", // Optional: Make text bold
                    color: "#333", // Adjust color if needed
                  }}
                >
                  Talentpedia
                </span>
              </Link>
              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/" className="nav-link active"
                    onClick={() => window.scrollTo(0, 0)}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/about-us" 
                    className="nav-link"
                    onClick={() => window.scrollTo(0, 0)}
                    >
                      About Us
                    </Link>
                  </li>

                  {/* Competitions */}
                  <li className="nav-item">
                    <a className="nav-link dropdown-toggle">Competitions</a>
                    <ul className="dropdown-menu">
                      {category.map((item, index) => (
                        <li className="nav-item" key={index}>
                          <Link
                            to={`/events/${item.id}`}
                            className="nav-link"
                            state={{
                              linkChange: true
                            }}
                            onClick={() => {
                              window.scrollTo(0, 0); 
                              setEvent([]); 
                              setIsNavOpen(false); 
                             
                              setCurrentPage(1); 
                            }}
                          >
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>

                  {/* Events */}
                  <li className="nav-item">
                    <a className="nav-link dropdown-toggle">For Companies</a>
                    <ul className="dropdown-menu">
                     
                        <li className="nav-item" >
                          <Link
                            to={`/submit-your-competition`}
                            className="nav-link"
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            Submit a new competition
                          </Link>
                        </li>
                   
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link to="/blogs" className="nav-link"  onClick={() => window.scrollTo(0, 0)}>
                      Inspirational Stories
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/faq" className="nav-link"  onClick={() => window.scrollTo(0, 0)} >
                      FAQ
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/contact" className="nav-link"  onClick={() => window.scrollTo(0, 0)} >
                      Contact Us
                    </Link>
                  </li>

                  {isLoggedIn && (
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle active"
                        href="#"
                        id="userDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src={userProfileImage}
                          alt="User"
                          className="rounded-circle profile-pic"
                          style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "8px",
                          }}
                        />
                        User
                      </a>

                      <div
                        className="dropdown-menu"
                        aria-labelledby="userDropdown"
                      >
                        <Link to="/my-profile" className="dropdown-item">
                          My Profile
                        </Link>
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={handleShowPasswordModal}
                        >
                          Change Password
                        </Link>
                        <ChangePasswordModal
                          show={showPasswordModal}
                          handleClose={handleClosePasswordModal}
                        />
                        <button
                          className="dropdown-item"
                          onClick={() => {
                            localStorage.removeItem("token");
                            localStorage.setItem("loggedIn", false);
                            setIsLoggedIn(false);
                            window.location.reload();
                          }}
                        >
                          Logout
                        </button>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
              {!isLoggedIn && (
                <div>
                  <ul className="navbar-nav text-right">
                    <li className="nav-item button-login home-contact-area">
                      <Link to="/signin">
                        <button
                          type="submit"
                          className="default-btn page-btn box-btn"
                        >
                          Sign In
                        </button>
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
